import logo from './logo.svg';
import './App.css';
import ScoreboardDisplay from './components/scoreboard/ScoreboardDisplay';


function App() {
  return (
    <div className="min-h-screen bg-gray-800 text-white p-4">
      <div className="container mx-auto max-w-4xl">
        <h1 className="text-3xl font-bold text-center mb-8">Futsal Scoreboard</h1>
        <ScoreboardDisplay />
      </div>
    </div>
  );
}

export default App;