import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

const ScoreboardDisplay = () => {
  return (
    <Card className="w-full bg-gray-900 text-white">
      <CardHeader>
        <CardTitle className="text-center">Live Score</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-3 gap-4">
        {/* Team Home */}
        <div className="text-center">
          <h2 className="text-2xl font-bold">Home Team</h2>
          <div className="text-6xl font-bold mt-4">0</div>
        </div>

        {/* Timer */}
        <div className="text-center">
          <div className="text-5xl font-bold">25:00</div>
          <div className="text-xl mt-2">Period 1</div>
        </div>

        {/* Team Away */}
        <div className="text-center">
          <h2 className="text-2xl font-bold">Away Team</h2>
          <div className="text-6xl font-bold mt-4">0</div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ScoreboardDisplay;